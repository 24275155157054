/*------------------*\
     MAIN SCRIPTS
\*------------------*/

// Ready
function ready(fn) {
  if (document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

// Set copyright year
const setCopyrightYear = () => {
  const copyright = document.getElementById("copyright-year");
  if (copyright) {
    copyright.appendChild(document.createTextNode(new Date().getFullYear()));
  }
};

// Init
const init = async () => {
  // Set the Copyright year
  setCopyrightYear();
};

// Initialise on Document Ready
ready(init);
